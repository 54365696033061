/**
 * 全站路由配置
 *
 * 建议:
 * 1. 代码中路由统一使用name属性跳转(不使用path属性)
 */
import Vue from "vue";
import VueRouter from "vue-router";
import http from "@/utils/httpRequest";
import { isURL } from "@/utils/validate";
import { clearLoginInfo } from "@/utils";

Vue.use(VueRouter);

// 解决重复点击路由报错的BUG https://blog.juanertu.com/archives/721f45a5
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const _import = require("./import-views");
// 全局路由(无需嵌套上左右整体布局)
const globalRoutes = [
    {
        path: "/404",
        component: () => import("@/views/common/404"),
        name: "404",
        meta: { title: "404未找到" },
    },
    {
        path: "/login",
        component: () => import("@/views/common/login"),
        name: "login",
        meta: { title: "登录" },
    },
];

// 主入口路由(需嵌套上左右整体布局)
const mainRoutes = {
    path: "/",
    component: () => import("@/views/main"),
    name: "main",
    redirect: { name: "home" },
    meta: { title: "主入口整体布局" },
    children: [
        // 通过meta对象设置路由展示方式
        // 1. isTab: 是否通过tab展示内容, true: 是, false: 否
        // 2. iframeUrl: 是否通过iframe嵌套展示内容, '以http[s]://开头': 是, '': 否
        // 提示: 如需要通过iframe嵌套展示内容, 但不通过tab打开, 请自行创建组件使用iframe处理!
        {
            path: "/home",
            component: () => import("@/views/common/home"),
            name: "home",
            meta: { title: "首页" },
        },
        {
            path: "/theme",
            component: () => import("@/views/common/theme"),
            name: "theme",
            meta: { title: "主题" },
        },
        {
            path: "/memory-cardedit",
            component: () => import("@/views/modules/content/memoryCard/memoryEdit"),
            name: "memory-cardedit",
            nokeep: true,
            meta: { title: "编辑记忆卡", isTab: true },
        },
        {
            path: "/nodeBook-edit",
            component: () => import("@/views/modules/content/nodeBook/nookEdiEdit"),
            name: "nodeBook-edit",
            nokeep: true,
            meta: { title: "编辑笔记", isTab: true },
        },
    ],
    beforeEnter(to, from, next) {
        if (to.name == `login`) {
            clearLoginInfo();
            next({ name: "login" });
        }
        let token = Vue.cookie.get("token");
        if (!token || !/\S/.test(token) || !from.fullPath) {
            clearLoginInfo();
            next({ name: "login" });
        }
        next();
    },
};

const router = new VueRouter({
    mode: "hash",
    scrollBehavior: () => ({ y: 0 }),
    isAddDynamicMenuRoutes: false, //` 是否已经添加动态(菜单)路由
    routes: globalRoutes.concat(mainRoutes),
});

router.beforeEach((to, from, next) => {
    // 添加动态(菜单)路由
    // 1. 已经添加 or 全局路由, 直接访问
    // 2. 获取菜单列表, 添加并保存本地存储
    if (
        router.options.isAddDynamicMenuRoutes ||
        fnCurrentRouteType(to, globalRoutes) === "global"
    ) {
        next();
    } else {
        http({
            url: http.adornUrl("/sys/menu/nav"),
            method: "post",
            params: http.adornParams(),
        })
            .then(({ data }) => {
                if (data && data.code === "500") {
                    clearLoginInfo();
                    next({ name: "login" });
                }
                if (data && data.code === "200") {
                    fnAddDynamicMenuRoutes(data.data.menuList);
                    router.options.isAddDynamicMenuRoutes = true;
                    sessionStorage.setItem(
                        "menuList",
                        JSON.stringify(data.data.menuList || "[]")
                    );
                    sessionStorage.setItem(
                        "permissions",
                        JSON.stringify(data.data.permissions || "[]")
                    );
                    next({ ...to, replace: true });
                } else {
                    sessionStorage.setItem("menuList", "[]");
                    sessionStorage.setItem("permissions", "[]");
                    next();
                }

            })
            .catch((e) => {
                console.log(
                    `%c${e} 请求菜单列表和权限失败，跳转至登录页！！`,
                    "color:blue"
                );
                router.push({ name: "login" });
            });
        next();
    }
});

/**
 * 判断当前路由类型, global: 全局路由, main: 主入口路由
 * @param {*} route 当前路由
 */
function fnCurrentRouteType(route, globalRoutes = []) {
    var temp = [];
    for (var i = 0; i < globalRoutes.length; i++) {
        if (route.path === globalRoutes[i].path) {
            return "global";
        } else if (
            globalRoutes[i].children &&
            globalRoutes[i].children.length >= 1
        ) {
            temp = temp.concat(globalRoutes[i].children);
        }
    }
    return temp.length >= 1 ? fnCurrentRouteType(route, temp) : "main";
}

/**
 * 添加动态(菜单)路由
 * @param {*} menuList 菜单列表
 * @param {*} routes 递归创建的动态(菜单)路由
 */
function fnAddDynamicMenuRoutes(menuList = [], routes = []) {
    console.log("menuList-----", menuList);
    var temp = [];
    for (var i = 0; i < menuList.length; i++) {
        if (menuList[i].childList && menuList[i].childList.length >= 1) {
            temp = temp.concat(menuList[i].childList);
        } else if (menuList[i].url && /\S/.test(menuList[i].url)) {
            menuList[i].url = menuList[i].url.replace(/^\//, "");
            menuList[i].path = menuList[i].url.replace("/", "-");
            var route = {
                path: menuList[i].url.replace("/", "-"),
                component: null,
                name: menuList[i].url.replace("/", "-"),
                meta: {
                    menuId: menuList[i].id,
                    title: menuList[i].name,
                    isDynamic: true,
                    isTab: true,
                    iframeUrl: "",
                },
            };
            // url以http[s]://开头, 通过iframe展示
            if (isURL(menuList[i].url)) {
                route["path"] = `i-${menuList[i].id}`;
                route["name"] = `i-${menuList[i].id}`;
                route["meta"]["iframeUrl"] = menuList[i].url;
            } else {
                try {
                    route["component"] =
                        _import(`modules/${menuList[i].url}`) || null;
                    // route['component'] = ()=>import(`@/views/modules/${menuList[i].url}.vue`) || null
                } catch (e) { }
            }
            routes.push(route);
        }
    }

    console.log("temp", temp);

    if (temp.length >= 1) {
        fnAddDynamicMenuRoutes(temp, routes);
    } else {
        mainRoutes.name = "main-dynamic";
        mainRoutes.children = routes;
        router.addRoutes([
            mainRoutes,
            { path: "*", redirect: { name: "404" } },
        ]);
        sessionStorage.setItem(
            "dynamicMenuRoutes",
            JSON.stringify(mainRoutes.children || "[]")
        );
        console.log("\n");
        console.log(
            "%c!<-------------------- 动态(菜单)路由 s -------------------->",
            "color:blue"
        );
        console.log(mainRoutes.children);
        console.log(
            "%c!<-------------------- 动态(菜单)路由 e -------------------->",
            "color:blue"
        );
    }
}
export default router;

import { Message } from 'element-ui';
function downloadFile() {
  return {
    timer: null,
    // a标签
    downDom(params, callback) {
      if (!params.url) {
        Message.error('请传入要下载的文件地址');
        return false;
      }

      const fileType = params.fileType ? params.fileType.replace(/\./g, '') : 'xls';
      let link = document.createElement('a');
      const title = params.type === 'link' ? params.title : `${params.title}_${Date.now()}.${fileType}`;
      link.setAttribute('download', title);
      link.setAttribute('href', params.url);
      document.body.appendChild(link);
      link.click();
      this.clearDom(link, 1000, callback);
    },

    // 清除Dom
    clearDom(link, time, callback) {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        document.body.removeChild(link);
        if (this.timer) clearTimeout(this.timer);
        this.timer = null;
        link = null;
        callback && callback();
      }, time);
    },

    // 图片
    imgUrl(params) {
      this.downDom({ url: params.link, ...params }, params.success);
    },

    // 链接
    fileLink(params) {
      this.downDom({ url: params.link, ...params }, params.success);
    },


    // 单纯得Blob下载
    fileToBlob(params) {
      const blob = new Blob([params.blob]);
      params.url = window.URL.createObjectURL(blob);
      this.downDom(params, () => {
        window.URL.revokeObjectURL(params.url);
        params.success && params.success();
      });
    },

    /**
     * 下载
     * @param {*} params
     * @param {String} link 接口或者是链接地址
     * @param {String} title 下载的标题
     * @param {String} fileType 文件后缀
     * @param {String} type 要下载得类型
     * @param {String} blob 要下载得blob文件流
     * @param {Function} success 成功得回调
     * @param {Function} error 失败得回调，只有通过接口请求才有
     * @param {Function} query 接口请求参数，只有通过接口请求才有
     */
    download(params) {
      if (!params.link && params.type !== 'blob') {
        Message.error('请传入要下载的文件路径或接口路径');
        return false;
      }

      if (!params.title) {
        Message.error('请传入要下载的文件名称');
        return false;
      }

      switch (params.type) {
        case 'link':
          // 链接
          this.fileLink(params);
          break;
        case 'img':
          // 图片
          this.imgUrl(params);
          break;
        case 'blob':
          // 接口已经下载好的文件流
          this.fileToBlob(params);
          break;
      }
    },
  };
}

export default downloadFile();

export default {
  namespaced: true,
  state: {
    ARTICLE_TYPES: {
      1: '普通文章',
      5: '帮助中心',
    }
  },
  mutations: {

  }
}

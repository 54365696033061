import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import ClipboardJS from 'clipboard';
import { Message } from 'element-ui';
import downloadFiles from './downloadFile';
/**
 * 获取uuid
 */
export function getUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
    })
}

/**
 * 下载文件
 * @param {*} data 如果type是blob data是文件流  type 是url data 是文件路径
 * @param {*} title 下载文件的名称
 * @param {*} type blob 文件流 link 文件路径, linkToBlob url文件路径转为Blob(这样可以修改下载的文件名称)
 * @param {*} timer 时间延迟
 */
export function downloadFile(params, callback) {
    downloadFiles.download(params, callback);
}

/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth(key) {
    return JSON.parse(sessionStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = 'id', pid = 'parentId') {
    var res = []
    var temp = {}
    for (var i = 0; i < data.length; i++) {
        temp[data[i][id]] = data[i]
    }
    for (var k = 0; k < data.length; k++) {
        if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
            if (!temp[data[k][pid]]['children']) {
                temp[data[k][pid]]['children'] = []
            }
            if (!temp[data[k][pid]]['_level']) {
                temp[data[k][pid]]['_level'] = 1
            }
            data[k]['_level'] = temp[data[k][pid]]._level + 1
            temp[data[k][pid]]['children'].push(data[k])
        } else {
            res.push(data[k])
        }
    }
    return res
}

/**
 * 清除登录信息
 */
export function clearLoginInfo() {
    Vue.cookie.delete('token')
    //store.commit('resetStore')
    router.options.isAddDynamicMenuRoutes = false
}
/**
 * 复制文案
 * 使用例子，click不能添加.stop
 * <span id="clipboardTarget" :data-clipboard-text="要复制的文案" @click="handleClipboard"></span>
 */
export function clipboardText(text) {
    const clipboard = new ClipboardJS('#clipboardTarget');
    console.log(clipboard);
    clipboard.on('success', e => {
        e.clearSelection();
        clipboard.destroy();
        Message.success(text || '复制成功');
    });
    clipboard.on('error', () => {
        clipboard.destroy();
        Message.error('复制失败');
    });
}
